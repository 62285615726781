<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links" v-if="admin">
        <sidebar-item
          :link="{
            name: 'Статистика',
            path: '/dashboard',
            icon: 'ni ni-tv-2 text-primary',
          }"
        >
        </sidebar-item>
      </template>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Клиники',
            path: '/clinics',
            icon: 'ni ni-building text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Врачи',
            path: '/doctors',
            icon: 'ni ni-fat-add text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Пациенты',
            path: '/patients',
            icon: 'ni ni-single-02 text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Заявки на скорую помощь',
            path: '/emergency',
            icon: 'ni ni-bus-front-12 text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Отзывы',
            path: '/feedbacks',
            icon: 'ni ni-chat-round text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Симптомы',
            path: '/symptoms',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Специализации',
            path: '/specialties',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Категории',
            path: '/categories',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Анализы',
            path: '/analyses',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Анализаторы',
            path: '/analysers',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Реф. Группы',
            path: '/references/groups',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Специализации клиник',
            path: '/clinic/specialties',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Состояние пациента',
            path: '/states',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Вакцины',
            path: '/vaccines',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Города',
            path: '/cities',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Районы',
            path: '/districts',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
      <template slot="admin">
        <sidebar-item
          :link="{
            name: 'Аптеки',
            path: '/pharmacies',
            icon: 'ni ni-spaceship text-primary'
          }">
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    },
    computed: {
      admin() {
        if (this.$store.getters["auth/user"]) {
          return this.$store.getters["auth/user"].role === 'super-admin';
        }
      },
    },
  };
</script>
<style lang="scss">
</style>
